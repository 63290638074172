export const countryList = [
  { value: 'Bangladesh', text: 'Bangladesh' },
  { value: 'Belgium', text: 'Belgium' },
  { value: 'Burkina Faso', text: 'Burkina Faso' },
  { value: 'Bulgaria', text: 'Bulgaria' },
  { value: 'Bosnia and Herzegovina', text: 'Bosnia and Herzegovina' },
  { value: 'Barbados', text: 'Barbados' },
  { value: 'Wallis and Futuna', text: 'Wallis and Futuna' },
  { value: 'Saint Barthelemy', text: 'Saint Barthelemy' },
  { value: 'Bermuda', text: 'Bermuda' },
  { value: 'Brunei', text: 'Brunei' },
  { value: 'Bolivia', text: 'Bolivia' },
  { value: 'Bahrain', text: 'Bahrain' },
  { value: 'Burundi', text: 'Burundi' },
  { value: 'Benin', text: 'Benin' },
  { value: 'Bhutan', text: 'Bhutan' },
  { value: 'Jamaica', text: 'Jamaica' },
  { value: 'Bouvet Island', text: 'Bouvet Island' },
  { value: 'Botswana', text: 'Botswana' },
  { value: 'Samoa', text: 'Samoa' },
  { value: 'Bonaire, Saint Eustatius and Saba ', text: 'Bonaire, Saint Eustatius and Saba ' },
  { value: 'Brazil', text: 'Brazil' },
  { value: 'Bahamas', text: 'Bahamas' },
  { value: 'Jersey', text: 'Jersey' },
  { value: 'Belarus', text: 'Belarus' },
  { value: 'Belize', text: 'Belize' },
  { value: 'Russia', text: 'Russia' },
  { value: 'Rwanda', text: 'Rwanda' },
  { value: 'Serbia', text: 'Serbia' },
  { value: 'East Timor', text: 'East Timor' },
  { value: 'Reunion', text: 'Reunion' },
  { value: 'Turkmenistan', text: 'Turkmenistan' },
  { value: 'Tajikistan', text: 'Tajikistan' },
  { value: 'Romania', text: 'Romania' },
  { value: 'Tokelau', text: 'Tokelau' },
  { value: 'Guinea-Bissau', text: 'Guinea-Bissau' },
  { value: 'Guam', text: 'Guam' },
  { value: 'Guatemala', text: 'Guatemala' },
  {
    value: 'South Georgia and the South Sandwich Islands',
    text: 'South Georgia and the South Sandwich Islands',
  },
  { value: 'Greece', text: 'Greece' },
  { value: 'Equatorial Guinea', text: 'Equatorial Guinea' },
  { value: 'Guadeloupe', text: 'Guadeloupe' },
  { value: 'Japan', text: 'Japan' },
  { value: 'Guyana', text: 'Guyana' },
  { value: 'Guernsey', text: 'Guernsey' },
  { value: 'French Guiana', text: 'French Guiana' },
  { value: 'Georgia', text: 'Georgia' },
  { value: 'Grenada', text: 'Grenada' },
  { value: 'United Kingdom', text: 'United Kingdom' },
  { value: 'Gabon', text: 'Gabon' },
  { value: 'El Salvador', text: 'El Salvador' },
  { value: 'Guinea', text: 'Guinea' },
  { value: 'Gambia', text: 'Gambia' },
  { value: 'Greenland', text: 'Greenland' },
  { value: 'Gibraltar', text: 'Gibraltar' },
  { value: 'Ghana', text: 'Ghana' },
  { value: 'Oman', text: 'Oman' },
  { value: 'Tunisia', text: 'Tunisia' },
  { value: 'Jordan', text: 'Jordan' },
  { value: 'Croatia', text: 'Croatia' },
  { value: 'Haiti', text: 'Haiti' },
  { value: 'Hungary', text: 'Hungary' },
  { value: 'Hong Kong', text: 'Hong Kong' },
  { value: 'Honduras', text: 'Honduras' },
  { value: 'Heard Island and McDonald Islands', text: 'Heard Island and McDonald Islands' },
  { value: 'Venezuela', text: 'Venezuela' },
  { value: 'Puerto Rico', text: 'Puerto Rico' },
  { value: 'Palestinian Territory', text: 'Palestinian Territory' },
  { value: 'Palau', text: 'Palau' },
  { value: 'Portugal', text: 'Portugal' },
  { value: 'Svalbard and Jan Mayen', text: 'Svalbard and Jan Mayen' },
  { value: 'Paraguay', text: 'Paraguay' },
  { value: 'Iraq', text: 'Iraq' },
  { value: 'Panama', text: 'Panama' },
  { value: 'French Polynesia', text: 'French Polynesia' },
  { value: 'Papua New Guinea', text: 'Papua New Guinea' },
  { value: 'Peru', text: 'Peru' },
  { value: 'Pakistan', text: 'Pakistan' },
  { value: 'Philippines', text: 'Philippines' },
  { value: 'Pitcairn', text: 'Pitcairn' },
  { value: 'Poland', text: 'Poland' },
  { value: 'Saint Pierre and Miquelon', text: 'Saint Pierre and Miquelon' },
  { value: 'Zambia', text: 'Zambia' },
  { value: 'Western Sahara', text: 'Western Sahara' },
  { value: 'Estonia', text: 'Estonia' },
  { value: 'Egypt', text: 'Egypt' },
  { value: 'South Africa', text: 'South Africa' },
  { value: 'Ecuador', text: 'Ecuador' },
  { value: 'Italy', text: 'Italy' },
  { value: 'Vietnam', text: 'Vietnam' },
  { value: 'Solomon Islands', text: 'Solomon Islands' },
  { value: 'Ethiopia', text: 'Ethiopia' },
  { value: 'Somalia', text: 'Somalia' },
  { value: 'Zimbabwe', text: 'Zimbabwe' },
  { value: 'Saudi Arabia', text: 'Saudi Arabia' },
  { value: 'Spain', text: 'Spain' },
  { value: 'Eritrea', text: 'Eritrea' },
  { value: 'Montenegro', text: 'Montenegro' },
  { value: 'Moldova', text: 'Moldova' },
  { value: 'Madagascar', text: 'Madagascar' },
  { value: 'Saint Martin', text: 'Saint Martin' },
  { value: 'Morocco', text: 'Morocco' },
  { value: 'Monaco', text: 'Monaco' },
  { value: 'Uzbekistan', text: 'Uzbekistan' },
  { value: 'Myanmar', text: 'Myanmar' },
  { value: 'Mali', text: 'Mali' },
  { value: 'Macao', text: 'Macao' },
  { value: 'Mongolia', text: 'Mongolia' },
  { value: 'Marshall Islands', text: 'Marshall Islands' },
  { value: 'Macedonia', text: 'Macedonia' },
  { value: 'Mauritius', text: 'Mauritius' },
  { value: 'Malta', text: 'Malta' },
  { value: 'Malawi', text: 'Malawi' },
  { value: 'Maldives', text: 'Maldives' },
  { value: 'Martinique', text: 'Martinique' },
  { value: 'Northern Mariana Islands', text: 'Northern Mariana Islands' },
  { value: 'Montserrat', text: 'Montserrat' },
  { value: 'Mauritania', text: 'Mauritania' },
  { value: 'Isle of Man', text: 'Isle of Man' },
  { value: 'Uganda', text: 'Uganda' },
  { value: 'Tanzania', text: 'Tanzania' },
  { value: 'Malaysia', text: 'Malaysia' },
  { value: 'Mexico', text: 'Mexico' },
  { value: 'Israel', text: 'Israel' },
  { value: 'France', text: 'France' },
  { value: 'British Indian Ocean Territory', text: 'British Indian Ocean Territory' },
  { value: 'Saint Helena', text: 'Saint Helena' },
  { value: 'Finland', text: 'Finland' },
  { value: 'Fiji', text: 'Fiji' },
  { value: 'Falkland Islands', text: 'Falkland Islands' },
  { value: 'Micronesia', text: 'Micronesia' },
  { value: 'Faroe Islands', text: 'Faroe Islands' },
  { value: 'Nicaragua', text: 'Nicaragua' },
  { value: 'Netherlands', text: 'Netherlands' },
  { value: 'Norway', text: 'Norway' },
  { value: 'Namibia', text: 'Namibia' },
  { value: 'Vanuatu', text: 'Vanuatu' },
  { value: 'New Caledonia', text: 'New Caledonia' },
  { value: 'Niger', text: 'Niger' },
  { value: 'Norfolk Island', text: 'Norfolk Island' },
  { value: 'Nigeria', text: 'Nigeria' },
  { value: 'New Zealand', text: 'New Zealand' },
  { value: 'Nepal', text: 'Nepal' },
  { value: 'Nauru', text: 'Nauru' },
  { value: 'Niue', text: 'Niue' },
  { value: 'Cook Islands', text: 'Cook Islands' },
  { value: 'Kosovo', text: 'Kosovo' },
  { value: 'Ivory Coast', text: 'Ivory Coast' },
  { value: 'Switzerland', text: 'Switzerland' },
  { value: 'Colombia', text: 'Colombia' },
  { value: 'China', text: 'China' },
  { value: 'Cameroon', text: 'Cameroon' },
  { value: 'Chile', text: 'Chile' },
  { value: 'Cocos Islands', text: 'Cocos Islands' },
  { value: 'Canada', text: 'Canada' },
  { value: 'Republic of the Congo', text: 'Republic of the Congo' },
  { value: 'Central African Republic', text: 'Central African Republic' },
  { value: 'Democratic Republic of the Congo', text: 'Democratic Republic of the Congo' },
  { value: 'Czech Republic', text: 'Czech Republic' },
  { value: 'Cyprus', text: 'Cyprus' },
  { value: 'Christmas Island', text: 'Christmas Island' },
  { value: 'Costa Rica', text: 'Costa Rica' },
  { value: 'Curacao', text: 'Curacao' },
  { value: 'Cape Verde', text: 'Cape Verde' },
  { value: 'Cuba', text: 'Cuba' },
  { value: 'Swaziland', text: 'Swaziland' },
  { value: 'Syria', text: 'Syria' },
  { value: 'Sint Maarten', text: 'Sint Maarten' },
  { value: 'Kyrgyzstan', text: 'Kyrgyzstan' },
  { value: 'Kenya', text: 'Kenya' },
  { value: 'South Sudan', text: 'South Sudan' },
  { value: 'Suriname', text: 'Suriname' },
  { value: 'Kiribati', text: 'Kiribati' },
  { value: 'Cambodia', text: 'Cambodia' },
  { value: 'Saint Kitts and Nevis', text: 'Saint Kitts and Nevis' },
  { value: 'Comoros', text: 'Comoros' },
  { value: 'Sao Tome and Principe', text: 'Sao Tome and Principe' },
  { value: 'Slovakia', text: 'Slovakia' },
  { value: 'South Korea', text: 'South Korea' },
  { value: 'Slovenia', text: 'Slovenia' },
  { value: 'North Korea', text: 'North Korea' },
  { value: 'Kuwait', text: 'Kuwait' },
  { value: 'Senegal', text: 'Senegal' },
  { value: 'San Marino', text: 'San Marino' },
  { value: 'Sierra Leone', text: 'Sierra Leone' },
  { value: 'Seychelles', text: 'Seychelles' },
  { value: 'Kazakhstan', text: 'Kazakhstan' },
  { value: 'Cayman Islands', text: 'Cayman Islands' },
  { value: 'Singapore', text: 'Singapore' },
  { value: 'Sweden', text: 'Sweden' },
  { value: 'Sudan', text: 'Sudan' },
  { value: 'Dominican Republic', text: 'Dominican Republic' },
  { value: 'Dominica', text: 'Dominica' },
  { value: 'Djibouti', text: 'Djibouti' },
  { value: 'Denmark', text: 'Denmark' },
  { value: 'British Virgin Islands', text: 'British Virgin Islands' },
  { value: 'Germany', text: 'Germany' },
  { value: 'Yemen', text: 'Yemen' },
  { value: 'Algeria', text: 'Algeria' },
  { value: 'United States', text: 'United States' },
  { value: 'Uruguay', text: 'Uruguay' },
  { value: 'Mayotte', text: 'Mayotte' },
  { value: 'United States Minor Outlying Islands', text: 'United States Minor Outlying Islands' },
  { value: 'Lebanon', text: 'Lebanon' },
  { value: 'Saint Lucia', text: 'Saint Lucia' },
  { value: 'Laos', text: 'Laos' },
  { value: 'Tuvalu', text: 'Tuvalu' },
  { value: 'Taiwan', text: 'Taiwan' },
  { value: 'Trinidad and Tobago', text: 'Trinidad and Tobago' },
  { value: 'Turkey', text: 'Turkey' },
  { value: 'Sri Lanka', text: 'Sri Lanka' },
  { value: 'Liechtenstein', text: 'Liechtenstein' },
  { value: 'Latvia', text: 'Latvia' },
  { value: 'Tonga', text: 'Tonga' },
  { value: 'Lithuania', text: 'Lithuania' },
  { value: 'Luxembourg', text: 'Luxembourg' },
  { value: 'Liberia', text: 'Liberia' },
  { value: 'Lesotho', text: 'Lesotho' },
  { value: 'Thailand', text: 'Thailand' },
  { value: 'French Southern Territories', text: 'French Southern Territories' },
  { value: 'Togo', text: 'Togo' },
  { value: 'Chad', text: 'Chad' },
  { value: 'Turks and Caicos Islands', text: 'Turks and Caicos Islands' },
  { value: 'Libya', text: 'Libya' },
  { value: 'Vatican', text: 'Vatican' },
  { value: 'Saint Vincent and the Grenadines', text: 'Saint Vincent and the Grenadines' },
  { value: 'United Arab Emirates', text: 'United Arab Emirates' },
  { value: 'Andorra', text: 'Andorra' },
  { value: 'Antigua and Barbuda', text: 'Antigua and Barbuda' },
  { value: 'Afghanistan', text: 'Afghanistan' },
  { value: 'Anguilla', text: 'Anguilla' },
  { value: 'U.S. Virgin Islands', text: 'U.S. Virgin Islands' },
  { value: 'Iceland', text: 'Iceland' },
  { value: 'Iran', text: 'Iran' },
  { value: 'Armenia', text: 'Armenia' },
  { value: 'Albania', text: 'Albania' },
  { value: 'Angola', text: 'Angola' },
  { value: 'Antarctica', text: 'Antarctica' },
  { value: 'American Samoa', text: 'American Samoa' },
  { value: 'Argentina', text: 'Argentina' },
  { value: 'Australia', text: 'Australia' },
  { value: 'Austria', text: 'Austria' },
  { value: 'Aruba', text: 'Aruba' },
  { value: 'India', text: 'India' },
  { value: 'Aland Islands', text: 'Aland Islands' },
  { value: 'Azerbaijan', text: 'Azerbaijan' },
  { value: 'Ireland', text: 'Ireland' },
  { value: 'Indonesia', text: 'Indonesia' },
  { value: 'Ukraine', text: 'Ukraine' },
  { value: 'Qatar', text: 'Qatar' },
  { value: 'Mozambique', text: 'Mozambique' },
];
const countries = [
  { code: 'GB', name: 'United Kingdom' },
  { code: 'US', name: 'United States' },
  { code: 'AU', name: 'Australia' },
  { code: 'CA', name: 'Canada' },
  { code: 'AE', name: 'United Arab Emirates' },
  // { code: 'DZ', name: 'Algeria' },
  // { code: 'AS', name: 'American Samoa' },
  // { code: 'AD', name: 'Andorra' },
  // { code: 'AO', name: 'Angola' },
  // { code: 'AI', name: 'Anguilla' },
  // { code: 'AQ', name: 'Antarctica' },
  // { code: 'AG', name: 'Antigua and Barbuda' },
  // { code: 'AR', name: 'Argentina' },
  // { code: 'AM', name: 'Armenia' },
  // { code: 'AW', name: 'Aruba' },
  // { code: 'AU', name: 'Australia' },
  // { code: 'AT', name: 'Austria' },
  // { code: 'AZ', name: 'Azerbaijan' },
  // { code: 'BS', name: 'Bahamas' },
  // { code: 'BH', name: 'Bahrain' },
  // { code: 'BD', name: 'Bangladesh' },
  // { code: 'BB', name: 'Barbados' },
  // { code: 'BY', name: 'Belarus' },
  // { code: 'BE', name: 'Belgium' },
  // { code: 'BZ', name: 'Belize' },
  // { code: 'BJ', name: 'Benin' },
  // { code: 'BM', name: 'Bermuda' },
  // { code: 'BT', name: 'Bhutan' },
  // { code: 'BO', name: 'Bolivia' },
  // { code: 'BQ', name: 'Bonaire, Sint Eustatius and Saba' },
  // { code: 'BA', name: 'Bosnia and Herzegovina' },
  // { code: 'BW', name: 'Botswana' },
  // { code: 'BV', name: 'Bouvet Island' },
  // { code: 'BR', name: 'Brazil' },
  // { code: 'IO', name: 'British Indian Ocean Territory' },
  // { code: 'BN', name: 'Brunei Darussalam' },
  // { code: 'BG', name: 'Bulgaria' },
  // { code: 'BF', name: 'Burkina Faso' },
  // { code: 'BI', name: 'Burundi' },
  // { code: 'KH', name: 'Cambodia' },
  // { code: 'CM', name: 'Cameroon' },
  // { code: 'CV', name: 'Cape Verde' },
  // { code: 'KY', name: 'Cayman Islands' },
  // { code: 'CF', name: 'Central African Republic' },
  // { code: 'TD', name: 'Chad' },
  // { code: 'CL', name: 'Chile' },
  // { code: 'CN', name: 'China' },
  // { code: 'CX', name: 'Christmas Island' },
  // { code: 'CC', name: 'Cocos (Keeling) Islands' },
  // { code: 'CO', name: 'Colombia' },
  // { code: 'KM', name: 'Comoros' },
  // { code: 'CG', name: 'Congo' },
  // { code: 'CD', name: 'Congo, the Democratic Republic of the' },
  // { code: 'CK', name: 'Cook Islands' },
  // { code: 'CR', name: 'Costa Rica' },
  // { code: 'CI', name: 'Cote dIvoire' },
  // { code: 'HR', name: 'Croatia' },
  // { code: 'CU', name: 'Cuba' },
  // { code: 'CW', name: 'Curacao' },
  // { code: 'CY', name: 'Cyprus' },
  // { code: 'CZ', name: 'Czech Republic' },
  // { code: 'DK', name: 'Denmark' },
  // { code: 'DJ', name: 'Djibouti' },
  // { code: 'DM', name: 'Dominica' },
  // { code: 'DO', name: 'Dominican Republic' },
  // { code: 'EC', name: 'Ecuador' },
  // { code: 'EG', name: 'Egypt' },
  // { code: 'SV', name: 'El Salvador' },
  // { code: 'GQ', name: 'Equatorial Guinea' },
  // { code: 'ER', name: 'Eritrea' },
  // { code: 'EE', name: 'Estonia' },
  // { code: 'ET', name: 'Ethiopia' },
  // { code: 'FK', name: 'Falkland Islands (Malvinas)' },
  // { code: 'FO', name: 'Faroe Islands' },
  // { code: 'FJ', name: 'Fiji' },
  // { code: 'FI', name: 'Finland' },
  // { code: 'FR', name: 'France' },
  // { code: 'GF', name: 'French Guiana' },
  // { code: 'PF', name: 'French Polynesia' },
  // { code: 'TF', name: 'French Southern Territories' },
  // { code: 'GA', name: 'Gabon' },
  // { code: 'GM', name: 'Gambia' },
  // { code: 'GE', name: 'Georgia' },
  // { code: 'DE', name: 'Germany' },
  // { code: 'GH', name: 'Ghana' },
  // { code: 'GI', name: 'Gibraltar' },
  // { code: 'GR', name: 'Greece' },
  // { code: 'GL', name: 'Greenland' },
  // { code: 'GD', name: 'Grenada' },
  // { code: 'GP', name: 'Guadeloupe' },
  // { code: 'GU', name: 'Guam' },
  // { code: 'GT', name: 'Guatemala' },
  // { code: 'GG', name: 'Guernsey' },
  // { code: 'GN', name: 'Guinea' },
  // { code: 'GW', name: 'Guinea-Bissau' },
  // { code: 'GY', name: 'Guyana' },
  // { code: 'HT', name: 'Haiti' },
  // { code: 'HM', name: 'Heard Island and McDonald Islands' },
  // { code: 'VA', name: 'Holy See (Vatican City State)' },
  // { code: 'HN', name: 'Honduras' },
  // { code: 'HK', name: 'Hong Kong' },
  // { code: 'HU', name: 'Hungary' },
  // { code: 'IS', name: 'Iceland' },
  // { code: 'IN', name: 'India' },
  // { code: 'ID', name: 'Indonesia' },
  // { code: 'IR', name: 'Iran, Islamic Republic of' },
  // { code: 'IQ', name: 'Iraq' },
  // { code: 'IE', name: 'Ireland' },
  // { code: 'IM', name: 'Isle of Man' },
  // { code: 'IL', name: 'Israel' },
  // { code: 'IT', name: 'Italy' },
  // { code: 'JM', name: 'Jamaica' },
  // { code: 'JP', name: 'Japan' },
  // { code: 'JE', name: 'Jersey' },
  // { code: 'JO', name: 'Jordan' },
  // { code: 'KZ', name: 'Kazakhstan' },
  // { code: 'KE', name: 'Kenya' },
  // { code: 'KI', name: 'Kiribati' },
  // { code: 'KP', name: 'Korea, Democratic People`s Republic of' },
  // { code: 'KR', name: 'Korea, Republic of' },
  // { code: 'KW', name: 'Kuwait' },
  // { code: 'KG', name: 'Kyrgyzstan' },
  // { code: 'LA', name: 'Lao People`s Democratic Republic' },
  // { code: 'LV', name: 'Latvia' },
  // { code: 'LB', name: 'Lebanon' },
  // { code: 'LS', name: 'Lesotho' },
  // { code: 'LR', name: 'Liberia' },
  // { code: 'LY', name: 'Libya' },
  // { code: 'LI', name: 'Liechtenstein' },
  // { code: 'LT', name: 'Lithuania' },
  // { code: 'LU', name: 'Luxembourg' },
  // { code: 'MO', name: 'Macao' },
  // { code: 'MK', name: 'Macedonia, the Former Yugoslav Republic of' },
  // { code: 'MG', name: 'Madagascar' },
  // { code: 'MW', name: 'Malawi' },
  // { code: 'MY', name: 'Malaysia' },
  // { code: 'MV', name: 'Maldives' },
  // { code: 'ML', name: 'Mali' },
  // { code: 'MT', name: 'Malta' },
  // { code: 'MH', name: 'Marshall Islands' },
  // { code: 'MQ', name: 'Martinique' },
  // { code: 'MR', name: 'Mauritania' },
  // { code: 'MU', name: 'Mauritius' },
  // { code: 'YT', name: 'Mayotte' },
  // { code: 'MX', name: 'Mexico' },
  // { code: 'FM', name: 'Micronesia, Federated States of' },
  // { code: 'MD', name: 'Moldova, Republic of' },
  // { code: 'MC', name: 'Monaco' },
  // { code: 'MN', name: 'Mongolia' },
  // { code: 'ME', name: 'Montenegro' },
  // { code: 'MS', name: 'Montserrat' },
  // { code: 'MA', name: 'Morocco' },
  // { code: 'MZ', name: 'Mozambique' },
  // { code: 'MM', name: 'Myanmar' },
  // { code: 'NA', name: 'Namibia' },
  // { code: 'NR', name: 'Nauru' },
  // { code: 'NP', name: 'Nepal' },
  // { code: 'NL', name: 'Netherlands' },
  // { code: 'NC', name: 'New Caledonia' },
  // { code: 'NZ', name: 'New Zealand' },
  // { code: 'NI', name: 'Nicaragua' },
  // { code: 'NE', name: 'Niger' },
  // { code: 'NG', name: 'Nigeria' },
  // { code: 'NU', name: 'Niue' },
  // { code: 'NF', name: 'Norfolk Island' },
  // { code: 'MP', name: 'Northern Mariana Islands' },
  // { code: 'NO', name: 'Norway' },
  // { code: 'OM', name: 'Oman' },
  // { code: 'PK', name: 'Pakistan' },
  // { code: 'PW', name: 'Palau' },
  // { code: 'PS', name: 'Palestine, State of' },
  // { code: 'PA', name: 'Panama' },
  // { code: 'PG', name: 'Papua New Guinea' },
  // { code: 'PY', name: 'Paraguay' },
  // { code: 'PE', name: 'Peru' },
  // { code: 'PH', name: 'Philippines' },
  // { code: 'PL', name: 'Poland' },
  // { code: 'PT', name: 'Portugal' },
  // { code: 'PR', name: 'Puerto Rico' },
  // { code: 'QA', name: 'Qatar' },
  // { code: 'RE', name: 'Réunion' },
  // { code: 'RO', name: 'Romania' },
  // { code: 'RU', name: 'Russian Federation' },
  // { code: 'RW', name: 'Rwanda' },
  // { code: 'BL', name: 'Saint Barthélemy' },
  // { code: 'SH', name: 'Saint Helena, Ascension and Tristan da Cunha' },
  // { code: 'KN', name: 'Saint Kitts and Nevis' },
  // { code: 'LC', name: 'Saint Lucia' },
  // { code: 'MF', name: 'Saint Martin (French part)' },
  // { code: 'PM', name: 'Saint Pierre and Miquelon' },
  // { code: 'VC', name: 'Saint Vincent and the Grenadines' },
  // { code: 'WS', name: 'Samoa' },
  // { code: 'SM', name: 'San Marino' },
  // { code: 'ST', name: 'Sao Tome and Principe' },
  // { code: 'SA', name: 'Saudi Arabia' },
  // { code: 'SN', name: 'Senegal' },
  // { code: 'RS', name: 'Serbia' },
  // { code: 'SC', name: 'Seychelles' },
  // { code: 'SL', name: 'Sierra Leone' },
  // { code: 'SG', name: 'Singapore' },
  // { code: 'SX', name: 'Sint Maarten (Dutch part)' },
  // { code: 'SK', name: 'Slovakia' },
  // { code: 'SI', name: 'Slovenia' },
  // { code: 'SB', name: 'Solomon Islands' },
  // { code: 'SO', name: 'Somalia' },
  // { code: 'ZA', name: 'South Africa' },
  // { code: 'GS', name: 'South Georgia and the South Sandwich Islands' },
  // { code: 'SS', name: 'South Sudan' },
  // { code: 'ES', name: 'Spain' },
  // { code: 'LK', name: 'Sri Lanka' },
  // { code: 'SD', name: 'Sudan' },
  // { code: 'SR', name: 'Suriname' },
  // { code: 'SJ', name: 'Svalbard and Jan Mayen' },
  // { code: 'SZ', name: 'Swaziland' },
  // { code: 'SE', name: 'Sweden' },
  // { code: 'CH', name: 'Switzerland' },
  // { code: 'SY', name: 'Syrian Arab Republic' },
  // { code: 'TW', name: 'Taiwan, Province of China' },
  // { code: 'TJ', name: 'Tajikistan' },
  // { code: 'TZ', name: 'Tanzania, United Republic of' },
  // { code: 'TH', name: 'Thailand' },
  // { code: 'TL', name: 'Timor-Leste' },
  // { code: 'TG', name: 'Togo' },
  // { code: 'TK', name: 'Tokelau' },
  // { code: 'TO', name: 'Tonga' },
  // { code: 'TN', name: 'Tunisia' },
  // { code: 'TR', name: 'Turkey' },
  // { code: 'TM', name: 'Turkmenistan' },
  // { code: 'TC', name: 'Turks and Caicos Islands' },
  // { code: 'TV', name: 'Tuvalu' },
  // { code: 'UG', name: 'Uganda' },
  // { code: 'UA', name: 'Ukraine' },

  // { code: 'UM', name: 'United States Minor Outlying Islands' },
  // { code: 'UY', name: 'Uruguay' },
  // { code: 'UZ', name: 'Uzbekistan' },
  // { code: 'VU', name: 'Vanuatu' },
  // { code: 'VE', name: 'Venezuela, Bolivarian Republic of' },
  // { code: 'VN', name: 'Viet Nam' },
  // { code: 'VG', name: 'Virgin Islands, British' },
  // { code: 'VI', name: 'Virgin Islands, U.S.' },
  // { code: 'WF', name: 'Wallis and Futuna' },
  // { code: 'EH', name: 'Western Sahara' },
  // { code: 'YE', name: 'Yemen' },
  // { code: 'ZM', name: 'Zambia' },
  // { code: 'ZW', name: 'Zimbabwe' },
];

export default countries;
